import Vue from 'vue'
import VueRouter from 'vue-router'

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Index',
  component: () => import('../views/Index.vue'),
  meta: {
    title: '黑蜻蜓',
    requireAuth: true
  }
},
{
  path: '/storeList',
  name: 'StoreList',
  component: () => import('../views/Store/storeList.vue'),
  meta: {
    title: '门店列表',
    requireAuth: true
  }
  },
{
  path: '/storeEdit',
  name: 'StoreEdit',
  component: () => import('../views/Store/storeEdit.vue'),
  meta: {
    title: '门店列表',
    requireAuth: true
  }
  },
{
  path: '/storeCouponList',
  name: 'StoreCouponList',
  component: () => import('../views/Store/storeCouponList.vue'),
  meta: {
    title: '套餐列表',
    requireAuth: true
  }
  },
{
  path: '/storeCouponEdit',
  name: 'StoreCouponEdit',
  component: () => import('../views/Store/storeCouponEdit.vue'),
  meta: {
    title: '添加套餐',
    requireAuth: true
  }
  },
  {
  path: '/storeCouponDetail',
  name: 'StoreCouponDetail',
  component: () => import('../views/Store/storeCouponDetail.vue'),
  meta: {
    title: '券的领取情况',
    requireAuth: true
  }
  },
{
  path: '/storePersonList',
  name: 'StorePersonList',
  component: () => import('../views/Store/storePersonList.vue'),
  meta: {
    title: '门店核销人',
    requireAuth: true
  }
  },
{
  path: '/storePersonAdd',
  name: 'StorePersonAdd',
  component: () => import('../views/Store/storePersonAdd.vue'),
  meta: {
    title: '添加核销人',
    requireAuth: true
  }
},
{
  path: '/withdraw_money',
  name: "/withdraw_money",
  component: () => import('../views/withdraw_money.vue'),
  meta: {
    title: '提现',
    requireAuth: true
  }
},
{
  path: '/benefit',
  name: 'Benefit',
  component: () => import('../views/Benefit.vue'),
  meta: {
    title: '收益明细',
    requireAuth: true
  }
},
{
  path: '/bendetail',
  name: 'Bendetail',
  component: () => import('../views/Bendetail.vue'),
  meta: {
    title: '收益明细',
    requireAuth: true
  }
},
{
  path: '/myorder',
  name: 'Myorder',
  component: () => import('../views/Myorder.vue'),
  meta: {
    title: '我的订单',
    requireAuth: true
  }
},
{
  path: '/freeuse',
  name: 'Freeuse',
  component: () => import('../views/Freeuse.vue'),
  meta: {
    title: '申请免费使用',
    requireAuth: true
  }
},
{
  path: '/merchants',
  name: 'merchants',
  component: () => import('../views/merchants.vue'),
  meta: {
    title: '商户信息',
    requireAuth: true
  }
},
{
  path: '/account',
  name: 'account',
  component: () => import('../views/account.vue'),
  meta: {
    title: '免费账号列表',
    requireAuth: true
  }
},
{
  path: '/login',
  name: 'Login',
  component: () => import('../views/Login.vue'),
  meta: {
    title: '登录'
  }
},{
  path: '/auth',
  name: 'Auth',
  component: () => import('../views/auth.vue'),
  meta: {
    title: '实名信息'
  }
},
{
  path: '/forget',
  name: 'Forget',
  component: () => import('../views/Forget.vue'),
  meta: {
    title: '忘记密码'
  }
},
{
  path: '/connectus',
  name: 'Connectus',
  component: () => import('../views/Connectus.vue'),
  meta: {
    title: "联系我们"
  }
},
{
  path: '/xy',
  name: 'Xy',
  component: () => import('../views/Xy.vue'),
  meta: {
    title: "联系我们"
  }
},
{
  path: '/attract',
  name: 'Attract',
  component: () => import('../views/Attract.vue'),
  meta: {
    title: "代理合作"
  }
},
{
  path: '/tlpay',
  name: 'tlpay',
  component: () => import('../views/tlpay.vue'),
  meta: {
    title: "黑蜻蜓支付"
  }
},
{
  path: '/sclogin',
  name: 'sclogin',
  component: () => import('../views/asclogin.vue'),
  meta: {
    title: "登录"
  }
},
{
  path: '/saoma',
  name: 'saoma',
  component: () => import('../views/asaoma.vue'),
  meta: {
    title: "扫码"
  }
},
{
  path: '/shebei',
  name: 'shebei',
  component: () => import('../views/ashebei.vue'),
  meta: {
    title: "设备"
  }
},
,
{
  path: '/abstshebei',
  name: 'abstshebei',
  component: () => import('../views/abstshebei.vue'),
  meta: {
    title: "设备"
  }
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() => {
  window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
  console.log(localStorage.getItem('token') && to.path == '/login',to.path)
  if (JSON.stringify(to.meta) !== '{}' && to.meta.title) {
    document.title = to.meta.title
  }
  // localStorage.setItem('token','')
  if(localStorage.getItem('token') && to.path == '/login'){
    next({
      name:"Index"
    })
  }else{
    next()
  }
  if(localStorage.getItem('token') && to.path == '/sclogin'){
    window.location.href="https://gzh.heiqingting.net/saoma"
    // next({
    //   name:'saoma'
    // })
  }else{
    next()
  }
  if (to.meta.requireAuth){
    if (!localStorage.getItem('token') && to.path != '/login') {
      next({
        name: "Login"
      });
    }else{
      next()
    }
  }else{
    next()
  }

})

export default router
