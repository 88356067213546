/*
 * @Author: inwen6
 * @Date: 2020-06-11 09:16:58
 * @LastEditTime: 2020-06-18 08:56:15
 * @LastEditors: your name
 * @Description: 请求方法二次封装
 * @FilePath: /minmax-mobile/src/api/http.js
 */
 
import axios from "axios";
import qs from 'qs' ;
import { Toast } from 'vant';
import { URL } from '../config/api_config';
import router from '../router/index'
// import store from "@/store/store";
let OSS = require('ali-oss');
let COS = require('cos-js-sdk-v5');
let toast = Toast;

// axios.defaults.withCredentials = true
// 请求拦截
axios.interceptors.request.use(config => {
  console.log(config.url.split('/')[config.url.split('/').length-1])
  console.log(',,,,,,,,,,,,,,,,,,')
  console.log(config.url.split('/')[config.url.split('/').length-1])
  if(config.url.split('/')[config.url.split('/').length-1]=='device'){
    localStorage.setItem('login',false)
  }
  console.log(config.url.split('/')[config.url.split('/').length-1],'111111111111111')
  if(config.url.split('/')[config.url.split('/').length-1]=='mobile'){
    localStorage.setItem('login',true)
  }
  if(localStorage.getItem('login')=='true' &&  config.url.indexOf('tengxun/cos/getTempToKen') == -1){
    toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration:0
    });
  }
  let token = localStorage.getItem("token")
  console.log(token)
  if(!token){
    // token = store.state.token
  }
  if (token&&config.url.split('/')[config.url.split('/').length-1]!='sign') {
    config.headers["Authorization"] = token;
  }
  return config
}, error => {
  return Promise.reject(error.status)
})

//响应拦截
axios.interceptors.response.use((res) => {
  console.log('进来了',res)
  let types = res.data.errno
  typeof types != 'string' ? types = String(types) : types
  if(localStorage.getItem('login') =='true'){
    toast.clear()
  }
  
  // if(res.data.state == '403' || res.data.state == '302' || res.data.state == '303' || res.data.state == '304' || res.data.state == '305') {
  //   localStorage.clear();
  //   alert('你的登录状态已过期,重新登录')
  //   router.replace('/login')
  // }
  return res.data
}, err => {
  console.log(err);
  if(localStorage.getItem('token')){
    // alert('你的登录状态已过期,重新登录')
    console.log(localStorage.getItem('login'))
    if(localStorage.getItem('login') =='true'){
      localStorage.clear();
      router.replace('/login')
    }else{
      localStorage.clear();
      router.replace('/sclogin')
      // 
    }
    
  }
    
})

// post 表单请求
const postFormData = (params,url) => { 
  let data = (params) ? qs.stringify(params) : '';
  return axios({
    url,
    data,
    method:'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
};

// post 头像
const postFormDataimg = (params,url) => { 
  console.log(params)
  var formData = new FormData();
  for(var i in params){
    formData.append( i,params[i]);
  }
  return axios({
    url,
    data:formData,
    method:'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
};

// post json请求
const postJson = (data, url) => {
  return axios({
    url,
    data,
    method:'POST',
    headers: {
      'Content-Type': 'application/json'
    },
  })
};

//get请求
const getJson = (params,url) =>{ 
  return axios.get(url, { params });
}

//oss头像上传

const aloss = (name,file,mobile) =>{
  console.log(mobile)
  if(!name&&!file) return
  let client = new OSS({
      region: 'oss-cn-hangzhou',
      //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，部署在服务端使用RAM子账号或STS，部署在客户端使用STS。
      accessKeyId: 'LTAI4GDicALgyKoKwGFzqcmW',
      accessKeySecret: '3CX3iUSnu8N8qflOpgdfTkuwWdqnsN',
      bucket: 'minmax'
  });
  async function put () {
      try {
          let leixing = name.split('.')[1]
          console.log(leixing)
          //object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
          let result = await client.put('jcj-service/avatar/'+Date.parse(new Date())/1000+mobile.slice(6)+'.'+leixing,file);
          console.log(result)
          return result
      } catch (e) {
          console.log(e);
      }
  }
  return put()
}

const alcoss = (path,name,file) =>{
  if(!name&&!file) return
  let client = new COS({
      getAuthorization: function (options, callback) {
      // 异步获取临时密钥  
      getJson({
          allowPrefix: '*',
          bucket: 'hqt-1305271135',
          region: 'ap-shanghai'
        },URL + '/tengxun/cos/getTempToKen').then(data => {
        var credentials = data.data && data.data.credentials;
        if (!data || !credentials) return console.error('credentials invalid');
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          XCosSecurityToken: credentials.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000900
        });
      })
    }

  });
  async function put() {
     var name3 = `${path}${new Date().getTime() + name}`
      try {
          //object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        let result = await client.putObject({
              Bucket: 'hqt-1305271135',
              /* 必须 */
              Region: 'ap-shanghai',
              /* 存储桶所在地域，必须字段 */
              Key: name3,
              /* 必须 */
              StorageClass: 'STANDARD',
              Body: file, // 上传文件对象 
        });

        var u1 = 'https://' + result.Location
        result.url = u1
    
        return result
      } catch (e) {
          console.log(e);
      }
  }
  return put()
}

const xxparseTime = (time, pattern) => {
  	if (!time) {
      return null
    }
    //{y}-{m}-{d} {h}:{i}:{s}'
    const format = pattern || '{y}-{m}-{d} {h}:{i}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
        time = parseInt(time)
      } else if (typeof time === 'string') {
        time = time.replace(new RegExp(/-/gm), '-');
      }
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      } 
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str

}


export {
  getJson,
  postJson,
  postFormData,
  postFormDataimg,
  aloss,
  alcoss,
  xxparseTime
}