import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { xxparseTime } from './api/http';
import {http} from './config/api_config'
import 'vant/lib/index.css';
import 'lib-flexible'
import '../src/assets/css/normalize.css'
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

Vue.config.productionTip = false

Vue.use(http);
Vue.use(Vant);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.prototype.xxparseTime = xxparseTime
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 防重复点击(指令实现)
Vue.directive('preventReClick', {
  inserted (el, binding) {
      el.addEventListener('click', () => {
      if (!el.disabled) {
          el.disabled = true
          setTimeout(() => {
          el.disabled = false
          }, binding.value || 1500)
      }
      })
  }
})

