/*
 * @Author: inwen6
 * @Date: 2020-06-14 20:59:35
 * @LastEditTime: 2020-06-18 10:30:27
 * @LastEditors: your name
 * @Description: 请求配置
 * @FilePath: /minmax-mobile/src/config/api_config.js
 */ 
import api from '../api/request';
const URL = 'https://merchant.heiqingting.net';
// const URL = 'http://192.168.0.153:5050';
// const URL = 'https://applettest.heiqingting.net'
var http = {}
console.log(api)
http.install = function(Vue){
    Vue.prototype.$http = api
}

export{
    URL,
    http
}