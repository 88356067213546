/*
 * @Author: inwen6
 * @Date: 2020-06-11 09:16:52
 * @LastEditTime: 2020-06-18 17:55:50
 * @LastEditors: your name
 * @Description: api管理
 * @FilePath: /minmax-mobile/src/api/request.js
 */

import { getJson, postFormData, postJson } from './http';
import { URL } from '../config/api_config';
var URL2 = 'https://applet.heiqingting.net';
// var URL2 = 'http://192.168.0.153:8052';
// var  URL2 = 'https://4426358yu2.goho.co'

const changeUrl2 = function(){
  // URL2 = 'https://4426358yu2.goho.co'
  // URL2 = 'http://192.168.0.153:8052'
  URL2 = 'https://applet.heiqingting.net'
}

//李浪总代理商的商户不展示 流水
const xxagentArr = ['3248', '1902', '1913', '1982', '2047', '2057', '2067', '2160', '2239', '2240', '2254',
  '2285', '2389', '2255', '2259', '2189', '2415', '2454', '2390', '1666']

//商户不展示流水的id
const xxmerArr = ['11657', '11699']

// const beishiTeMobileArr = ['13867445571','13867445573']
// //贝思特研发 测试接口账号
// const beishiTeMobileArr2 = ['13867445573']

const beishiTeMobileArr = ['15158190492','18820197281','13867445705']
//贝思特研发  线上接口账号
const beishiTeMobileArr2 = ['15158190492','13867445705']

//该代理下的商户 首页提现按钮消失
const withdrawAgentArr = ['3518','3027','2234','817','3669']

//周洪禀代理上下的商户 景兰文旅'2114' 15911749774 2023-02以后的显示比例固定为70
const xxzhouMer = '2114'


//获取验证码
const msgSend = (params) => postFormData(params, `${URL}/sms/msgSend`)
//密码登录
const login = (params) => postFormData(params, `${URL}/login`)
//验证码登录
const loginmobile = (params) => postFormData(params, `${URL}/login/mobile`)
//查询商户信息
const merchantstores = (params) => getJson(params, `${URL}/merchant/stores`)
//修改密码
const updatePwd = (params) => postFormData(params, `${URL}/updatePwd`)
//获取本月未出账单
const thisMonth = (params) => getJson(params, `${URL}/merchant/bill/thisMonth`)
//获取多月已出账单
const months = (params) => getJson(params, `${URL}/merchant/bill/months`)
//获取某月天数账单明细
const dayDetail = (params) => getJson(params, `${URL}/merchant/bill/dayDetail`)
//获取某月已出账单
const oneMonth = (params) => getJson(params, `${URL}/merchant/bill/oneMonth`)
//公众号绑定用户提现信息
const bind = (params) => getJson(params, `${URL}/withdraw/merchant/bind`)
//微信授权登录
const weChat = (params) => getJson(params, `${URL}/login/weChat`)
// 获取商户信息
const getInfo = (params) => postFormData(params, `${URL}/getInfo`)
//是否大客户门店
const isBigCustom = (params) => getJson(params, `${URL}/order/isBigCustom`)
//签名
const wechatsign = (params) => getJson(params, `${URL}/wechat/sign`)
//公众号商户申请免费账号
const apply = (params) => getJson(params, `${URL}/freeuser/apply`)
//公众号获取可提现金额和服务费信息
const withdrawMoney = (params) => getJson(params, `${URL}/withdraw/tencent/withdrawMoney`)
//商户提现
const merchanty = (params) => getJson(params, `${URL}/withdraw/merchant`)
//获取商户提现记录
const myWithdraw = (params) => getJson(params, `${URL}/withdraw/merchant/myWithdraw`)
//大客户获取订单详情
const orderlist = (params) => getJson(params, `${URL}/order/list`)
// 加盟合作
const joinApply = (params) => postJson(params, `https://applet.heiqingting.net/ow/joinApply/add`)
//设备方登录
const loginmobile2 = (params) => postFormData(params, `${URL2}/login/mobile/device`)
//获取验证码
const msgSend2 = (params) => postFormData(params, `${URL2}/sms/msgSend`)
//获取设备详细信息
const deviceInfo = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ? `${URL2}/bstfactory/deviceInfo`:`${URL2}/factoryTest/deviceInfo`)
//开输出全部弹出
const popAllOutput = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ?`${URL2}/bstfactory/popAllTest`:`${URL2}/factoryTest/popAllOutput`)
//不开输出全部弹出
const popAllShutOutput = (params) => getJson(params, `${URL2}/factoryTest/popAllShutOutput`)
//开输出弹出
const popOutput = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ?`${URL2}/bstfactory/popOutputTest`:`${URL2}/factoryTest/popOutput`)
//关输出弹出
const popShutOutput = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ?`${URL2}/bstfactory/popShutOutputTest`:`${URL2}/factoryTest/popShutOutput`)
//刷新
const refresh = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ? `${URL2}/bstfactory/refresh`:`${URL2}/factoryTest/refresh`)
//重启机柜
const restart = (params) => getJson(params, `${URL2}/factoryTest/restart`)
//解绑
const unbindDevice = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ?`${URL2}/bstfactory/unbindDeviceSn`:`${URL2}/factoryTest/unbindDevice`)
const bindDevice = (params) => getJson(params, (params.deviceSn && params.deviceSn.startsWith('HQTF')) ?`${URL2}/bstfactory/bindDeviceSn`:`${URL2}/factoryTest/unbindDevice`)
//验证
const check = (params) => getJson(params, `${URL2}/factoryTest/check`)

const developmenttest = (params) => getJson(params, `${URL2}/bstfactory/developmenttest`)

const checkB = (params) => getJson(params, `${URL2}/bstfactory/check`)

const tlpay = (params) => postFormData(params, `${URL2}/tlpay/wechatOrder`)

const getTlOpenid = (params) => postFormData(params, `${URL2}/tlpay/getOpenId`)
// 获取大客户门店未到账收益
const getBigNonArrivalIncome = (params) => getJson(params, `${URL}/order/getBigNonArrivalIncome`)

// 获取当前登录商户数据
const getDetails = (params) => getJson(params, `${URL}/merchant/getDetails`)

//新门店展示列表
const getNewStoreList = (params) => getJson(params, `${URL}/merchant/storeadcs`)
//新 门店信息添加
const addAdcStoreInfo = (params) => postFormData(params, `${URL}/merchantadc/add`)
//新 门店信息变更
const updateAdcStoreInfo = (params) => getJson(params, `${URL}/merchantadc/update`)
//新 门店是否展示变更
const updateAdcStoreStatus = (params) => getJson(params, `${URL}/merchantadc/updateStatus`)
//新 门店信息查询
const getAdcStoreInfo = (params) => getJson(params, `${URL}/merchantadc/query`)
// 新 券列表
const getAdcCouponList = (params) => getJson(params, `${URL}/merchantadc/querycoupon`)
// 新 券修改状态
const updateAdcCouponInfo = (params) => getJson(params, `${URL}/merchantadc/updatecoupon`)
// 新 添加券
const addAdcCoupon = (params) => postFormData(params, `${URL}/merchantadc/addcoupon`)
//新 券的领取情况
const querycouponuser = (params) => getJson(params, `${URL}/merchantadc/querycouponuser`)
//新 门店核销人列表
const getAdcUserList = (params) => getJson(params, `${URL}/merchantadc/queryadcuser`)
//新 门店核销人删除
const deleteAdcUser = (params) => getJson(params, `${URL}/merchantadc/deleteadcuser`)
//新 门店核销人新增
const addAdcUser = (params) => postFormData(params, `${URL}/merchantadc/addadcuser`)

//新 获取实名信息
const searchMerchantRealId = (params) => getJson(params, `${URL}/withdraw/searchMerchantRealId`)

//添加实名信息
const registerMerchantRealId = (params) => postFormData(params, `${URL}/withdraw/registerMerchantRealId`)

//是否可查看月账单详情
const checkLookBillDetail = (params) => getJson(params, `${URL}/merchant/checkClikMonbill`)


//区域广告
const getAdvertList = (params) => getJson(params, `${URL}/advert/queryList`)

const agentCheckSup = (params) => getJson(params, `${URL}/checkSup`)


export default {
  xxagentArr,
  xxmerArr,
  xxzhouMer,
  beishiTeMobileArr,
  beishiTeMobileArr2,
  withdrawAgentArr,
  changeUrl2,
  msgSend,
  login,
  loginmobile,
  merchantstores,
  updatePwd,
  thisMonth,
  months,
  dayDetail,
  oneMonth,
  bind,
  weChat,
  getInfo,
  isBigCustom,
  wechatsign,
  apply,
  withdrawMoney,
  merchanty,
  myWithdraw,
  orderlist,
  joinApply,
  loginmobile2,
  msgSend2,
  deviceInfo,
  popAllOutput,
  popAllShutOutput,
  popOutput,
  popShutOutput,
  refresh,
  restart,
  bindDevice,
  unbindDevice,
  check,
  checkB,
  tlpay,
  getTlOpenid,
  getBigNonArrivalIncome,
  getDetails,
  getNewStoreList,
  addAdcStoreInfo,
  updateAdcStoreInfo,
  updateAdcStoreStatus,
  getAdcStoreInfo,
  getAdcCouponList,
  updateAdcCouponInfo,
  addAdcCoupon,
  addAdcUser,
  deleteAdcUser,
  getAdcUserList,
  querycouponuser,
  searchMerchantRealId,
  registerMerchantRealId,
  getAdvertList,
  agentCheckSup,
  checkLookBillDetail,
  developmenttest
  



}